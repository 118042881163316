<template>
  <div v-if="!loading">
    <b-container>
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center top-up-in-process"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/topup-fail.png')"
            >
            <h3 class="my-1">
              Topup fail
            </h3>
            <p class="mb-2 des">
              Your transaction cannot be completed.
            </p>
            <user-hint />
          </div>

          <div class="summary p-2 card">
            <h4 class="text-left mb-2">
              Summary
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div class="font-14 text-placeholder">
                Client
              </div>
              <strong>
                {{ fullName }}
              </strong>
            </div>
            <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
              <div class="font-14 text-placeholder">
                Order amount
              </div>
              <strong>
                {{ currencyFormat(amount) }} USD
              </strong>
            </div>
            <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
              <div class="font-14 text-placeholder">
                Payment method
              </div>
              <img
                :src="tazapayLogo"
                height="16px"
                alt="tazapay"
              >
            </div>

            <div class="align-items-center d-flex flex-row justify-content-between mb-1">
              <div class="font-14 text-placeholder">
                Service charge
              </div>
              <strong class="text-primary">{{ currencyFormat(serviceCharge) }} USD</strong>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-1">
              <div class="font-14 text-placeholder">
                Tazapay fee
              </div>
              <div>
                <div
                  class="d-flex align-items-center"
                >
                  <strong class="text-primary">{{ currencyFormat(tazapayFee) }} USD</strong>
                </div>
              </div>
            </div>

            <div class="align-items-center d-flex flex-row justify-content-between">
              <div class="font-14 text-placeholder">
                Tax
              </div>
              <strong class="text-primary">{{ currencyFormat(tax) }} USD</strong>
            </div>

            <hr class="hr-custom">
            <div class="d-flex align-items-center justify-content-between">
              <div class="font-14 text-placeholder text-left">
                Total Amount = Amount + Service charge + Tax + Tazapay fee
              </div>
            </div>
            <h3 class="mt-2 text-left">
              {{ currencyFormat(bankTransferAmount) }} USD
            </h3>
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.push('/payment/add-fund?tab=tazapay')"
            >
              Recharge
            </btn-loading>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue'
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import Ripple from 'vue-ripple-directive'
import { toastification } from '@core/mixins/toast'
import UserHint from '@/components/UserHint.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    UserHint,
    BContainer,
    BRow,
    BCol,
  },

  directives: {
    Ripple,
  },

  mixins: [stepGuideMixin, numberFormatMixin, paymentMixin, toastification],

  data() {
    return {
      amount: this.$route.query.amount || '',
      bankTransferAmount: this.$route.query.bankTransferAmount || '',
      paymentMethod: this.$route.query.paymentMethod || '',
    }
  },

  computed: {
    ...mapGettersAuth(['user', 'loading', 'generalConfigs']),

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    tazapayLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/logo-tazapay.svg')
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.tazapay || 0
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.tazapayGate || 0
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount)
        .toLocaleString('en-US')
    },

    tazapayFee() {
      return this.amountFormat(this.methodFee * this.amount)
        .toLocaleString('en-US') || 0
    },

    tax() {
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },
  },

  created() {
    const { fullPath } = this.$route
    const checkUrl = fullPath.toLowerCase().includes('/payment/tazapay/error')

    if (checkUrl && this.hasStepGuide) {
      this.$router.push('/payment/add-fund?tab=tazapay')
      this.toastFailure('Top up fail!')
    }
  },

  methods: {
    goToGuide() {
      window.open(process.env.VUE_APP_GUIDE_TO_TOP_UP_USDT)
    },
  },
}
</script>

<style lang="scss" scoped>
.container-box {
  max-width: 340px;
  margin: 0 auto;
}

.summary {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  padding: 24px 17px;
}

.btn-recharge{
  font-size: 14px;
  padding: 11px 20px;
}

.top-up-in-process{
  max-width: 410px;
  margin: 0;
  padding: 0;
}
</style>
